import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [16,2,5,4,6,8];

export const dictionary = {
		"/(loggedOut)": [~37,[15]],
		"/(loggedOut)/agb": [38,[15]],
		"/(loggedOut)/datenschutz": [39,[15]],
		"/(loggedOut)/id/error": [40,[16]],
		"/(loggedOut)/id/register": [41,[16]],
		"/(loggedOut)/id/signin-old": [43,[16]],
		"/(loggedOut)/id/signin": [42,[16]],
		"/(loggedOut)/id/verify-request": [44,[16]],
		"/(loggedOut)/impressum": [45,[15]],
		"/(loggedIn)/(onboarding)/invites": [~17,[2,3]],
		"/(loggedIn)/(onboarding)/onboarding-business-data": [~19,[2,3,5]],
		"/(loggedIn)/(onboarding)/onboarding": [~18,[2,3,4]],
		"/(loggedIn)/[orgId]/(onboarded)/calendars": [21,[2,6,8,9],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/calendars/[id]": [~22,[2,6,8,9,10],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/calendars/[id]/schedule": [23,[2,6,8,9,10],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/calendars/[id]/settings": [24,[2,6,8,9,10],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/calendars/[id]/setup": [25,[2,6,8,9,10],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/dashboard": [~26,[2,6,8],[,7]],
		"/(loggedIn)/[orgId]/(deactivated)/deactivated": [20,[2,6],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables": [27,[2,6,8,11],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables/[id]": [~28,[2,6,8,11,12],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables/[id]/automations": [~30,[2,6,8,11,12],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables/[id]/settings": [31,[2,6,8,11,12],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables/[id]/setup": [32,[2,6,8,11,12],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/tables/[id]/[entryId]": [~29,[2,6,8,11,13],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/team": [~33,[2,6,8,14],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/team/billing": [~34,[2,6,8,14],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/team/integrations": [~35,[2,6,8,14],[,7]],
		"/(loggedIn)/[orgId]/(onboarded)/webhooks": [~36,[2,6,8],[,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';